@import url('https://fonts.googleapis.com/css2?family=Tangerine&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  font-family: "Ubuntu", sans-serif;
}

.logo-animation-add {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo-animation-remove {
  -webkit-animation: fadeOut 1s forwards;
  animation: fadeOut 1s forwards;
  animation-delay: 1s;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.remove-div {
  position: absolute;
  opacity :0;
  z-index:-1
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.logo-move {
  position: absolute;
  top:50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 1s ease-in-out;
}

.logo-move-animation {
  top: 1rem;
  left: 7.5rem;
  transform: none;
  width: 15rem !important;
}

a:hover {
  text-decoration: none;
  color: #3e747e;
}

section {
  padding: 3rem 0rem;
}

.bg-gray {
  background-color: #efefef;
}

/* Header */
.animation-nav {
  z-index: -1;
}
.navbar {
  background-color: transparent;
  color: black;
  transition: 0.5s;

}
.navbar .navbar-logo {
  width: 20rem;
  transition: 0.5s;
}

.navbar-scroll {
  background-color: white;
  border-bottom: 1px solid black;
  transition: 0.5s;
}
.navbar-scroll .navbar-logo {
  width: 13rem;
  transition: 0.5s;
}

.active_item {
  border-bottom: 2px solid #3e747e;
}

/* Slider */
.slider {
  height: 100vh;
  background: url("../resources/images/homeBg.jpg") no-repeat;
  background-size: cover;
}

.layer {
  background-color: rgba(7, 9, 21, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* About */
.about-image {
  background: url("../resources/images/about/about-header-image.jpg") no-repeat
    fixed;
  background-size: cover;
}

.about-image-table {
  max-height: 100%;
  max-width: 100%;
}

/* Values */
.values {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: black;
}

.table-bordered td,
.table-bordered th {
  border: 2px solid;
}

/* Specialities */
.specialities {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: #ffffff;
}

ul li {
  font-size: 1.2rem;
  margin-bottom: "1rem";
}

/* Pricing */
.pricing {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: black;
}

.fee {
  margin-top: 12rem;
  margin-bottom: 3rem;
}

.card-header {
  color: black;
  font-size: 18pt;
}

.card-body {
  color: black;
  font-size: 12pt;
}

.pricing-image {
  background: url("../resources/images/pricing/pricing.jpeg") no-repeat fixed;
  background-size: cover;
}

.margin-top {
  margin-top: 8rem;
}

/* Contact */
.contact {
  position: relative;
  text-align: center;
  margin-top: 5rem;
  margin-bottom: 3rem;
}

.contact-form {
  background-color: #fcf7f1;
  position: relative;
  text-align: center;
}

.fa-3x {
  margin-bottom: 10px;
}

.fa-youtube {
  color: #ff0007;
}

.fa-facebook {
  color: #3e67af;
}

/* Tokens of gratitude */
.italic-quote {
  font-size: 2.5rem;
  font-family: 'Tangerine', cursive, italic;
}

font-family: 'Tangerine', cursive;

/* Footer */
.footer {
  color: black;
  font-weight: bold;
  background-color: white;
}

/* MobileMenu */
.mobile-menu {
  height: 100vh;
  box-shadow: rgba(0, 0, 0, 0.5) 2px 0px 5px;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 60%;
  z-index: 999999;
  background-color: #ffffff;
  align-items: center;
  font-size: 1.3rem;
  transform: translateX(-100%);
  transition: transform 0.3s ease-out;
}
.mobile-menu.open {
  transform: translateX(0);
}
.mobile-menu .nav-item {
  margin-top: 1rem;
}
.mobile-menu .navbar-link {
  color: #000000;
}

/* Responsive */
.slider-text {
  font-size: 3rem;
}
.img-couple {
  width: 30%;
}

@media only screen and (max-width: 600px) {
  .slider-text {
    font-size: 2rem;
  }
  .img-couple {
    width: 100%;
  }
  .header-text {
    font-size: 2rem !important;
    margin-top: 7rem !important;
  }
  .pricing {
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    color: black;
  }
}

.col-md-4 {
    margin-top:20px;
}

@media only screen and (min-width : 1200px) {
    .container {
      //width: 1350px;
      max-width: 1350px;
    }
}


.footer-link {
  color: #000;
}


.payment-box {
  height: 240px;
  padding: 25px;
}

.payment-input {
  padding: 4px 10px;
  border-radius: 6px;
  border: 1px solid #c8c8c8;
  font-size: 0.9em;
  font-weight: 400;
  color: #363636;
  margin: 5px 0;
}

.payment-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  height: 216px;
}

.payment-label {
  color: #333333;
  font-weight: 300;
  font-size: 0.9em;
  text-transform: capitalize;
}

.payment-label > input{
  margin-right: 6px;
}

#show-loader {
  margin: 0;
  text-align: center;
}

.payment-button {
  background-color: #0091d6;
  color: #fff !important;
  outline: 0;
  border: 0 none transparent;
  text-transform: uppercase;
  text-align: center;
  font-size: 14px;
  font-family: 'Open Sans',sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.2px;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  font-weight: bold;
  border-radius: 2px;
  margin: 0;
  padding: 0 10px;
  line-height: 40px;
  height: 40px;
  -webkit-box-shadow: inset 0 -1px 0 0 rgba(0,0,0,0.2);
  box-shadow: inset 0 -1px 0 0 rgba(0,0,0,0.2);
  -webkit-transition: background-color .2s;
  transition: background-color .2s;
  position: relative;
  display: inline-block;
  float: none;
  left: 0;
  vertical-align: top;
  min-width: 110px;
  -webkit-transition: opacity .5s;
  -moz-transition: opacity .5s;
  -ms-transition: opacity .5s;
  transition: opacity .5s;
  margin-top: 20px;
  opacity: 0.86;
  cursor: no-drop;
  margin: 20px auto;
}
